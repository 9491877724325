import { APIClient } from "./api_helper";
import * as url from "./url_helper";

const api = new APIClient();
// Gets the logged in user data from local session

// Login Method
export const postJwtLogin = (data) => api.create(url.POST_JWT_LOGIN, data);

// Register Method
export const postJwtRegister = (url, data) => {
  return api.create(url, data).catch((err) => {
    var message;
    if (err.response && err.response.status) {
      switch (err.response.status) {
        case 404:
          message = "Sorry! the page you are looking for could not be found";
          break;
        case 500:
          message =
            "Sorry! something went wrong, please contact our support team";
          break;
        case 401:
          message = "Invalid credentials";
          break;
        default:
          message = err[1];
          break;
      }
    }
    throw message;
  });
};

/**
 * Returns the authenticated user
 */

export const getAuthenticatedUser = () => {
  var res = localStorage.getItem("authUser");
  if (res) {
    return JSON.parse(res);
  } else {
    return null;
  }
};

export const updateAuthenticatedUser = (user) => {
  localStorage.setItem("authUser", JSON.stringify(user));
};

// Gets the logged in user data from local session
export const getLoggedInUser = () => {
  const user = localStorage.getItem("__accessToken");
  return user;
  // if (user) return JSON.parse(user);
  // return null;
};

// //is user is logged in
export const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Media
// Updated to send JSON instead of FormData
export const postMediaCreate = (data) =>
  api.postJson(url.POST_MEDIA_CREATE, data);

export const getHeadingImages = (id) =>
  api.get(url.GET_HEADING_IMAGES + "/" + id, null);
export const getImageById = (id) =>
  api.get(url.GET_IMAGE_BY_ID + "/" + id, null);

export const downloadMedia = (id) => {
  if (id !== null && id !== undefined) {
    return process.env.REACT_APP_API_NEW_URL + "/api/media/fileById/" + id;
  }
  return "";
};
// Assuming the api.get function is adjusted to accept a params object correctly
export const getPresignedUrl = (fileName: string, fileType: string) => {
  // Construct the query string
  const queryString = new URLSearchParams({ fileName, fileType }).toString();
  // Call the existing `get` method with the constructed URL
  return api.get(`${url.GET_PRESIGNED_URL}?${queryString}`);
};


export const deleteMedia = (id) =>
  api.delete(url.DELETE_MEDIA + "/" + id, null);

//CLIENT REVIEW

export const getClientReviews = (userId) =>
  api.get(url.GET_ALL_REVIEWS + "/" + userId, null);

export const getClientReview = (id) =>
  api.get(url.GET_ONE_REVIEW_BY_ID + "/" + id, null);

export const addClientReview = (data) =>
  api.create(url.POST_CREAT_REVIEW, data);

export const updateClientReview = (id, data) =>
  api.update(url.UPDATE_CLIENT_REVIEW + "/" + id, data);

export const deleteClientReview = (id) =>
  api.delete(url.DELETE_REVIEW + "/" + id, null);

//USER

export const getUserInfos = (data) => api.create(url.POST_GET_USER_INFO, data);

export const uploadListingREA = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["AgentId"],
    portalType: "REA",
  });
export const uploadListingDomain = (data) =>
  api.create(url.UPLOAD_LISTINGS_DOMAIN, {
    ...data,
    AgentId: getAuthenticatedUser()["DomainAgencyId"],
  });
export const uploadListingHomes = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["HomesAgentId"],
    portalType: "homes",
  });
export const uploadListingHomely = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["HomelyAgentId"],
    portalType: "homely",
  });
export const uploadListingRS = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["AgentId"],
    portalType: "RS",
  });
export const uploadListingSoho = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["SohoAgentId"],
    portalType: "soho",
  });
export const uploadListingOTH = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["OTHAgentId"],
    portalType: "oth",
  });
export const uploadListingView = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["ViewAgentId"],
    portalType: "view",
  });
export const uploadListingRent = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["RentAgentId"],
    portalType: "rent",
  });
export const uploadListingRealty = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["RealtyAgentId"],
    portalType: "realty",
  });
export const uploadListingCPG = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["CPGAgentId"],
    portalType: "cpg",
  });
export const uploadListingIrene = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["IreneAgentId"],
    portalType: "irene",
  });

export const uploadListingRateMyAgent = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["RateMyAgentAgentId"],
    portalType: "ratemyagent",
  });

export const uploadListingListingLoop = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["ListingLoopAgentId"],
    portalType: "listingloop",
  });

export const uploadListingFarmBuy = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["FarmBuyAgentId"],
    portalType: "farmbuy",
  });

export const uploadListingHorseProperty = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["HorsePropertyAgentId"],
    portalType: "horseproperty",
  });

export const uploadListingFarmProperty = (data) =>
  api.create(url.UPLOAD_LISTINGS_REA, {
    ...data,
    AgentId: getAuthenticatedUser()["FarmPropertyAgentId"],
    portalType: "farmproperty",
  });
  
export const getREAListingPortals = (listingId) =>
  api.get(url.GET_ALL_REA_PORTALS + "/" + listingId, null);

//LISTING PORTAL

export const getListingPortals = (listingId) =>
  api.get(url.GET_ALL_LISTING_PORTALS + "/" + listingId, null);

export const getListingPortal = (id) =>
  api.get(url.GET_ONE_PORTAL_BY_ID + "/" + id, null);

export const addListingPortal = (data) =>
  api.create(url.POST_CREAT_PORTAL, data);

export const updateListingPortal = (id, data) =>
  api.update(url.UPDATE_CLIENT_PORTAL + "/" + id, data);

export const deleteListingPortal = (id) =>
  api.delete(url.DELETE_PORTAL + "/" + id, null);

//LISTINGER
export const getListingers = () => {
  // Retrieve the authenticated user's data from local storage
  const authUserString = localStorage.getItem("authUser");
  if (!authUserString) {
    console.error("Authentication user data is missing.");
    return; // Optionally handle this case as needed
  }
  const authUser = JSON.parse(authUserString);
  const clientId = authUser?.ClientId;
  const userId = authUser?.UserId;

  if (!clientId || !userId) {
    console.error("ClientId or UserId is missing for the authenticated user");
    return; // Optionally handle this case as needed
  }

  // Append the UserId as a query parameter
  return api.get(
    `${url.GET_ALL_LISTINGERS}/${clientId}?userId=${userId}`,
    null
  );
};

export const getActiveListingers = () => {
  // Retrieve the authenticated user's data from local storage
  const authUserString = localStorage.getItem("authUser");
  if (!authUserString) {
    console.error("Authentication user data is missing.");
    return; // Optionally handle this case as needed
  }
  const authUser = JSON.parse(authUserString);
  const clientId = authUser?.ClientId;
  const userId = authUser?.UserId;

  if (!clientId || !userId) {
    console.error("ClientId or UserId is missing for the authenticated user");
    return; // Optionally handle this case as needed
  }

  // Append the UserId as a query parameter
  return api.get(
    `${url.GET_ACTIVE_LISTINGERS}/${clientId}?userId=${userId}`,
    null
  );
};

// export const getListingers = () => api.get(`${url.GET_ALL_LISTINGERS}/${getAuthenticatedUser()['ClientId']}`, null);
export const getDomainListingers = () =>
  api.get(
    url.GET_ALL_LISTINGERS +
      "/" +
      getAuthenticatedUser()["DomainAgencyId"] +
      "/domain",
    null
  );
export const getListinger = (id) =>
  api.get(url.GET_ONE_LISTINGER_BY_ID + "/" + id, null);

// export const addListinger = (data) =>
//   api.create(url.POST_CREAT_LISTINGER, {
//     ...data,
//     AgentId: getAuthenticatedUser()["AgentId"],
//   });

export const getAllUniquePropertyTags = () => {
  const authUserString = localStorage.getItem("authUser");
  if (!authUserString) {
    console.error("Authentication user data is missing.");
    return; // Optionally handle this case as needed
  }
  const authUser = JSON.parse(authUserString);
  const clientId = authUser?.ClientId;

  return api.get(url.GET_ALL_UNIQUE_PROPERTY_TAGS + "/" + clientId, null);
}
export const addListinger = async (data) => {
  try {
    const response = await api.create(url.POST_CREAT_LISTINGER, {
      ...data,
      AgentId: getAuthenticatedUser()["AgentId"],
    });
    console.log("Full Axios Response:", response); // Debug: Log full response
    return response; // Return full Axios response
  } catch (error) {
    throw error; // Re-throw to handle it in the calling function
  }
};




export const updateListinger = (id, data) =>
  api.update(url.UPDATE_CLIENT_LISTINGER + "/" + id, data);

export const deleteListinger = (id) =>
  api.delete(url.DELETE_LISTINGER + "/" + id, null);

export const uploadListingerImage = (data) =>
  api.postFormData(url.UPLOAD_LISTING_IMAGE, data);

export const uploadListingerMedia = (data) =>
  api.postFormData(url.UPLOAD_LISTING_MEDIA, data);

export const getAddressByPlaceId = (data) =>
  api.create(url.GET_ADDRESS_BY_PLACE_ID, data);

// export const createListingsPdf = id => api.get(url.CREATE_LISTING_PDF + "/"
//   + id, null);
export const createListingsPdf = ({ listingId, brochureType }) => {
  // Assuming `url.CREATE_LISTING_PDF` is the base part of your endpoint URL
  // Construct the URL with the listingId and brochureType as query parameters
  const requestUrl = `${
    url.CREATE_LISTING_PDF
  }/${listingId}?brochureType=${encodeURIComponent(brochureType)}`;

  // Call the API with the constructed URL
  return api.get(requestUrl, null);
};

export const createStockListPdf = (param) =>
  api.get(url.CREATE_STOCKLIST_PDF, param);

//LISTIN Agent

export const getListingAgents = () =>
  api.get(
    url.LISTING_AGENT_API_URL + "/" + getAuthenticatedUser()["ClientId"],
    null
  );

export const getListingAgent = (id) =>
  api.get(url.LISTING_AGENT_API_URL + "/agent/" + id, null);

export const addListingAgent = (data) =>
  api.create(url.LISTING_AGENT_API_URL, {
    ...data,
    ClientId: getAuthenticatedUser()["ClientId"],
  });

export const updateListingAgent = (id, data) =>
  api.update(url.LISTING_AGENT_API_URL + "/" + id, data);



export const deleteListingAgent = (id) =>
  api.delete(url.LISTING_AGENT_API_URL + "/" + id, null);

//Contact apis
export const createContact = (data) =>
  api.create(url.CONTACT_API_URL, {
    ...data,
    clientId: getAuthenticatedUser()["ClientId"],
  });
export const getAllContact = () =>
  api.create(url.CONTACT_API_URL + "/get_all_contact", {
    clientId: getAuthenticatedUser()["ClientId"],
  });
export const getAnalyzeContact = () =>
  api.create(url.CONTACT_API_URL + "/get_analyze_contact", {
    clientId: getAuthenticatedUser()["ClientId"],
  });
export const getContactById = (id) => api.get(url.CONTACT_API_URL + "/" + id);
export const updateContact = (id, data) =>
  api.update(url.CONTACT_API_URL + "/" + id, data);
export const deleteContact = (id) => api.delete(url.CONTACT_API_URL + "/" + id);

//Preference apis
export const createPreference = (data) =>
  api.create(url.PREFERENCE_API_URL, {
    ...data,
  });
export const getPreferenceById = (id) => api.get(url.PREFERENCE_API_URL + "/" + id);
export const getPreferenceByContactId = (id) => api.get(url.PREFERENCE_API_URL + "/get_by_contact_id/" + id);
export const updatePreference = (id, data) =>
  api.update(url.PREFERENCE_API_URL + "/" + id, data);
export const deletePreference = (id) => api.delete(url.PREFERENCE_API_URL + "/" + id);
export const getMatchedProperties = (id) => api.get(url.PREFERENCE_API_URL + "/get_matched_properties/" + id);

//Tasks apis
export const createTask = (data) =>
  api.create(url.TASK_API_URL, {
    ...data,
    clientId: getAuthenticatedUser()["ClientId"],
    userId: getAuthenticatedUser()["UserId"],
  });
export const getAllTask = (data) =>
  api.create(url.TASK_API_URL + "/getTaskById", {
    clientId: getAuthenticatedUser()["ClientId"],
    ...data,
  });

export const getTaskById = (id) => api.get(url.TASK_API_URL + "/" + id);
export const updateTask = (id, data) =>
  api.update(url.TASK_API_URL + "/" + id, data);
export const deleteTask = (id) => api.delete(url.TASK_API_URL + "/" + id);
export const getAnalyzeTask = () =>
  api.create(url.TASK_API_URL + "/get_analyze_task", {
    clientId: getAuthenticatedUser()["ClientId"],
  });

//Blogs apis
export const createBlog = (data) =>
  api.create(url.BLOG_API_URL, {
    ...data,
    clientId: getAuthenticatedUser()["ClientId"],
  });
export const getAllBlog = (data) =>
  api.create(url.BLOG_API_URL + "/getBlogsById", {
    clientId: getAuthenticatedUser()["ClientId"],
    ...data,
  });

export const getBlogById = (id) => api.get(url.BLOG_API_URL + "/" + id);
export const updateBlog = (id, data) =>
  api.update(url.BLOG_API_URL + "/" + id, data);
export const deleteBlog = (id) => api.delete(url.BLOG_API_URL + "/" + id);

//Domain
export const authDomain = () => {
  // window.open('https://auth.domain.com.au/v1/connect/authorize?client_id=client_6416634590d3f4d8ba27e1c24c854ffb&redirect_uri=https://portal2.realsoftware.com.au/crm/contacts/all&response_type=code&scope=openid%20profile%20api_listings_read%20api_agencies_read%20api_enquiries_read%20offline_access', '_blank');
  window.location.href =
    "https://auth.domain.com.au/v1/connect/authorize?client_id=client_6416634590d3f4d8ba27e1c24c854ffb&redirect_uri=https://portal2.realsoftware.com.au/crm/contacts/all&response_type=code&scope=openid%20profile%20api_listings_read%20api_agencies_read%20api_enquiries_read%20offline_access";
};

export const authCodeDomain = (code) => {
  return api.create(url.CONTACT_API_URL + "/auth_code_domain", {
    clientId: getAuthenticatedUser()["ClientId"],
    code: code,
  });
};

// Marketing Campaign
export const createMarketingCampaign = (data) =>
  api.create(url.CREATE_MARKETING_CAMPAIGN, {
    ...data,
    clientId: getAuthenticatedUser()["ClientId"],
    userId: getAuthenticatedUser()["UserId"],
  });
export const getMarketingCampaign = (id) =>
  api.get(url.GET_ONE_MARKETING_CAMPAIGN_BY_ID + "/" + id, null);
export const getAllMarketingCampaigns = () =>
  api.get(
    url.GET_ALL_MARKETING_CAMPAIGNS + "/" + getAuthenticatedUser()["ClientId"],
    null
  );
export const updateMarketingCampaign = (id, data) =>
  api.update(url.UPDATE_MARKETING_CAMPAIGN + "/" + id, data);
export const deleteMarketingCampaign = (id) =>
  api.delete(url.DELETE_MARKETING_CAMPAIGN + "/" + id, null);
export const getTags = () =>
  api.get(
    url.GET_CONTACT_TAGS + "/" + getAuthenticatedUser()["ClientId"],
    null
  );
  export const activateMarketingCampaign = async (payload) => {
    return api.create(url.ACTIVATE_MARKETING_CAMPAIGN, payload);
  };
  
// Function to create branding
export const createBranding = (data) => api.create(url.CREATE_BRANDING, data);

// Function to get branding by ClientId
export const getBrandingByClientId = (clientId) =>
  api.get(`${url.GET_BRANDING_BY_CLIENT_ID}/${clientId}`, null);
export const getContactByClientId = (clientId) =>
  api.get(`${url.GET_CONTACT_BY_CLIENT_ID}/${clientId}`, null);

export const getEmailPreview = async (data) => {
  try {
    // Directly return the response from the API call
    return await api.create(url.GET_EMAIL_PREVIEW, data);
  } catch (err) {
    console.error("Error fetching email preview:", err);
    throw err;
  }
};

// Function to update branding
// export const updateBranding = (brandingId, data) => api.update(`${url.UPDATE_BRANDING}/${brandingId}`, data);
export const updateBranding = async (clientId, data) => {
  return api.update(`${url.UPDATE_BRANDING}/${clientId}`, data);
};

// Function to update social URLs
export const updateSocialUrls = async (clientId, data) => {
  return api.update(`${url.UPDATE_SOCIAL_URLS}/${clientId}`, data);
};

// Assuming api.update correctly makes a PUT request and the backend is expecting a PUT request
export const updateContactDynamoDB = async (clientId, contactData) => {
  return api.update(`${url.UPDATE_CONTACT_INFO}/${clientId}`, contactData);
};

// Note APIs
export const createNote = (data) => api.create(url.CREATE_NOTE, data);

export const getNotesByContactId = (contactId) =>
  api.get(`${url.GET_NOTES_BY_CONTACT_ID}/${contactId}`);

export const updateNote = (id, data) =>
  api.update(`${url.UPDATE_NOTE}/${id}`, data);

export const deleteNote = (id) => api.delete(`${url.DELETE_NOTE}/${id}`);
